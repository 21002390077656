import { Button } from 'antd';
import { CmtModalButton } from '../../components/CmtModalButton';
import { ProjectGeneralSettings } from '../../components/CmtProjects/project-settings/project-general-settings';
import { Project } from '../../services/project.service';

export function ProjectSettingSettingsButton(props: {
  data: Project;
  onSuccess?: () => void;
}) {
  return (
    <CmtModalButton
      title={props.data.name}
      width={860}
      trigger={({ show }) => {
        return (
          <Button
            onClick={show}
            className={['project-setting-btn', props.data.platform].join(' ')}
          >
            项目配置
          </Button>
        );
      }}
    >
      {({ hide }) => {
        return (
          <ProjectGeneralSettings
            onDelete={() => {
              hide();
              props.onSuccess?.();
            }}
            onSuccess={props.onSuccess}
            projectId={props.data.id}
          ></ProjectGeneralSettings>
        );
      }}
    </CmtModalButton>
  );
}
